<style scoped>
</style>

<template>
  <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
                <Select slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="goodsName">名称</Option>
                  <Option value="goodsCode">编码</Option>
                </Select>
              </i-input>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
          <Button style="margin-left: 10px;" v-if="$authFunsProxy.in" @click="addClick" icon="md-add" type="primary">入库</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:action="{ row }">
          <Button @click="detail(row)" type="text" size="small">库存</Button>
        </template>
        <template v-slot:total="{ row }">
          {{ row.total === null ? '无库存' : row.total }}{{ row.total !== null ? (row.goodsUnit || '') : '' }}
        </template>
      </Table>
    </template>
    <template v-slot:footer>
      <Drawer placement="right" title="入库" v-if="$authFunsProxy.in" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
        <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
          <StockForm type="in" ref="form" />
        </DrawerAction>
      </Drawer>
      <Drawer placement="right" title="库存明细" v-if="$authFunsProxy.get" :mask-closable="false" closable transfer draggable :width="80" v-model="status.detail">
        <StockDetail :is-page="false" style="height: 100%;margin: 0;padding: 0;" v-if="chooseItem && status.detail" :data="chooseItem" />
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../components/page/base.vue'
import DrawerAction from '../../components/drawer/action.vue'
import request from '../../api/index'
import StockForm from '../../components/stock/form/stock.vue'
import StockDetail from './stock.vue'

export default {
  components: { AppPage, StockForm, DrawerAction, StockDetail },
  data () {
    return {
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {
        key: 'goodsName',
        value: null
      },
      loading: {
        load: false,
        submit: false,
      },
      dataList: [],
      status: {
        form: false,
        detail: false
      },
      chooseItem: null
    }
  },
  computed: {
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    columns () {
      return [
        { title: '名称', key: 'goodsName' },
        { title: '编码', key: 'goodsCode' },
        { title: '规格型号', key: 'goodsGgxh' },
        { title: '类型', key: 'goodsUseTypeText' },
        { title: '数量', key: 'total', slot: 'total' },
        { title: '操作', key: 'action', slot: 'action', width: 180 },
      ]
    }
  },
  methods: {
    detail (item) {
      this.chooseItem = item
      this.status.detail = true
    },
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (!data) {
          return this.$Message.warning('请完整填写表单')
        }
        this.loading.submit = true
        await request.post('/goods_stock/direct_in', data)
        this.$Message.success('操作成功')
        this.status.form = false
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    addClick () {
      this.status.form = true
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.query.key = 'goodsName'
      this.query.value = null
      this.query.status = 'all'
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }

      const params = {}

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_stock/count', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.goodsUseTypeText = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType).label
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
    }
  }
}
</script>
